import styled from 'styled-components'
import { brandFont, colour } from 'styles/variables';

const PageHeader = ({ title, subTitle }) => {
  if (!title) {
    return null;
  }

  return (
    <StyledHeader>
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 30px 0;
`;

const Title = styled.h1`
  margin-bottom: 0;
  font-size: 2.2rem;
  font-family: ${brandFont};
  font-weight: 700;
  color: ${colour.gray900};
  letter-spacing: -0.1px;
`;

const Subtitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  color: ${colour.gray500};
`;

export { PageHeader };
