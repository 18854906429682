import { Spinner } from '@a-cloud-guru/kermit';
import { Layout } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

import { Container } from 'components/container';
import { colour } from 'styles/variables';
import { imagePaths } from 'utils/commonImageUrls';

const SparseLayout = ({ children }) => (
  <StyledLayout>
    <Row>
      <Header>
        <Logo src={imagePaths.logoLightDesktop} alt="" />
      </Header>
    </Row>
    <Content>
      {children || <Container><Spinner size="3rem" /></Container>}
    </Content>
    <Row>
      <Footer>A Cloud Guru © 2018</Footer>
    </Row>
  </StyledLayout>
);

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const Header = styled.div`
  padding: 3rem 0 1.6rem 0;
  text-align: center;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled(Row)`
  flex: 1;
`;

const Footer = styled.div`
  color: ${colour.gray500};
  text-align: center;
  margin: 2rem 0;
`;

export { SparseLayout };
