import { withRouter } from 'next/router';
import { Button, colours } from '@a-cloud-guru/rainbow-ui';
import React from 'react';
import styled from 'styled-components';
import { PageHeader } from 'components/header/PageHeader';
import { SparseLayout } from 'layouts/SparseLayout';
import { Flex, Box } from 'components/box';
import { lighten }from 'polished';
import * as bugsnag from 'lib/bugsnag';
import Router from 'next/router';

export const NotFound = () => (
  <Flex
    mt="150px"
    flexDirection="column"
    alignItems="center"
  >
    <PageNotFoundImage src="/images/illustration-sadcloud.png" />
    <Flex
      mt="28px"
      flexDirection="column"
      alignItems="center"
    >
      <Header>Oh no!</Header>
      <Subtitle>The page you&rsquo;re looking for doesn&rsquo;t exist.</Subtitle>
      <Box mt="28px">
        <Button type="primary" height="42px" onClick={() => Router.push('/dashboard')}>
          Return to the Dashboard
        </Button>
      </Box>
    </Flex>
  </Flex>
);

class NextError extends React.Component {
  static getInitialProps({ res, err }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;

    return { statusCode };
  }

  componentDidMount() {
    const { router, statusCode } = this.props;

    if (statusCode >= 400) {
      const errMessage = `${statusCode} error at ${router.pathname}`;
      bugsnag.send(new Error(errMessage));
    }
  }

  getErrorDescription(code) {
    switch (code) {
      case 500:
        return 'Internal server error';

      case 404:
        return "This page doesn't exist";

      default:
        return 'An error has occurred';
    }
  }

  renderErrorPage(statusCode) {
    if (statusCode !== 404) {
      return (
        <PageHeader
          title={statusCode ? statusCode.toString() : ''}
          subTitle={this.getErrorDescription(statusCode)}
        />
      );
    }

    return <NotFound />;
  }

  render() {
    const { statusCode } = this.props;

    return (
      <SparseLayout>
        {this.renderErrorPage(statusCode)}
      </SparseLayout>
    );
  }
}

const PageNotFoundImage = styled.img`
  width: 300px;
  height: 200px;
`;

const Header = styled.h1`
  font-size: 42px;
  font-weight: 800;
  color: #000;
`;

const Subtitle = styled.div`
  font-size: 24px;
  color: ${lighten(0.05, colours.darkGrey900)};
`;

export default withRouter(NextError);
